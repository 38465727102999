import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    backgroundColor: "#F8F9FB",
    display: "flex",
    minHeight: "90vh",
    flexDirection: "column",
    fontFamily: "Roboto, sans-serif"
  },
  headerApp: {
    margin: "18px 10%",
    "& .appLogo": {
      width: "30%",
      maxWidth: 114
    }
  },
  sectionTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    margin: "80px 15% 0",
    [theme.breakpoints.down('md')]: {
      margin: "60px 15% 0",
    },
    [theme.breakpoints.down('sm')]: {
      margin: "40px 15% 0",
    },
    "& .title": {
      fontSize: 60,
      margin: 8,
      [theme.breakpoints.down('md')]: {
        fontSize: 32,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 24
      },
    },
    "& .body": {
      fontSize: 32,
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      },
    },
    "& .testflight": {
      width: "80%",
      maxWidth: 197,
      marginTop: 24
    },
    "& .image": {
      width: "100%"
    },
  },
  sectionFeatures: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F3F9FF",
    "& .containerFeature": {
      margin: "62px 10%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",  
      flexDirection: "row",
      [theme.breakpoints.up('xl')]: {
        margin: "62px 30%",
      },
      [theme.breakpoints.down('lg')]: {
        margin: "62px 19%",
      },
      // width: "70%",
      [theme.breakpoints.down('md')]: {
        flexDirection: "column",
      },
      gap: 63,
      "& .column": {
        display: "flex",
        flexDirection: "column",
        gap: 24,
        width: "50%",
        [theme.breakpoints.down('md')]: {
          width: "100%",
        },  
        "& .row": {
          display: "flex",
          flexDirection: "row",
          gap: 16,
          "& .image": {
            width: "50%",
            maxWidth: 168,
            maxHeight: 156,
            height: "auto",
            aspectRatio: 1
          },
          "& .title": {
            display: "flex",
            maxHeight: 156,
            backgroundImage: "url(/assets/img/cursor.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right bottom",
            alignItems: "center",
            backgroundSize: 57,
            width: "51%",
            [theme.breakpoints.down('md')]: {
              backgroundSize: 50,
            },
            [theme.breakpoints.down('sm')]: {
              backgroundSize: 40,
            },    
            "& .titleFeature": {
              fontSize: 34,
              display: "flex",
              flexWrap: "wrap",
              height: "fit-content",
              margin: 0,
              "& .square": {
                margin: 0,
                padding: "0px 10px 0px 2px",
                border: "5px solid #C0D2EB",
              },
              [theme.breakpoints.down('md')]: {
                fontSize: 30,
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 26
              },    
            },
          },
          "& .bodyFeature": {
            fontSize: 24,
            [theme.breakpoints.down('md')]: {
              fontSize: 20,
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: 16
            },    
          },

        }  
      },
      "& .imgFeature": {
        maxWidth: 570,
        width: "50%",
        [theme.breakpoints.down('md')]: {
          width: "100%",
        },  
      }
    },
  },
  sectionMac: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#F8F9FB",
    color: "#F8F9FB",
    position: "relative",
    "& .background": {
      position: "absolute",
      backgroundColor: "#0F2131",
      width: "100%",
      height: "70%",
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        height: "80%",
      },    
    },
    "& .content": {
      position: "relative",
      zIndex: 2,
      margin: "0 10%"
    },
    "& .title": {
      margin: "80px 0 0 0",
      fontSize: 34,
      [theme.breakpoints.down('md')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 26
      },    
    },
    "& .body": {
      fontSize: 24,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",
      "& .text": {
        marginTop: 19
      }
    },
    "& .imgMac": {
      width: "100%",
      maxWidth: 707,
    },
    "& .svg": {
      position: "relative"
    }
  },
  sectionTry: {
    display: "flex",
    flexDirection: "row",
    margin: "200px 20% 0",
    gap: 60,
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      margin: "100px 20% 0",
    },
    "& .column": {
      display: "flex",
      flexDirection: "column",
      "& .title": {
        fontSize: 60,
        margin: 8,
        [theme.breakpoints.down('md')]: {
          fontSize: 32,
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 24
        },  
      },
      "& .body": {
        fontSize: 32,
        [theme.breakpoints.down('md')]: {
          fontSize: 24,
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: 16
        },
      },
      "& .testflight": {
        width: "70%",
        maxWidth: 197,
        marginTop: 24
      },  
    },
    "& .barcode": {
      maxWidth: 295,
      maxHeight: 295,
      width: "100%",
      height: "100%",
      aspectRatio: 1
    }
  },
  footerApp: {
    margin: "18px 10%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .appLogo": {
      width: "80%",
      maxWidth: 114
    },
    "& .copy": {
      fontSize: 16
    }
  },

}));
